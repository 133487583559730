/* --------------------------------
Grid
https://getbootstrap.com/docs/4.2/layout/grid/
-------------------------------- */
/*
Hover adds a gray background in the: after element.
This is for usability.

Usage:
@include state-indicator();
*/
/*
Utility functions to calculate colors

Source:
https://medium.com/@jonnykates/automating-colour-contrast-ratios-with-sass-e201f3b52797
*/
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--color-primary-bg);
  -webkit-text-fill-color: var(--color-primary-bg);
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s; }

/* Custom inputs */
.custom-control {
  position: relative;
  display: inline-flex; }
  .custom-control__label {
    font-weight: var(--type-weight-base);
    color: var(--color-text);
    line-height: var(--component-heading-line-height); }

[type="radio"] {
  border-radius: 50%; }
  [type="radio"]:before {
    border-radius: 50%;
    height: calc(100% - 6px);
    width: calc(100% - 6px); }

[type="checkbox"] {
  border-radius: 3px; }
  [type="checkbox"]:checked:before {
    content: '\e975';
    font-family: var(--font-icon);
    width: 100%;
    height: 100%; }

[type="checkbox"],
[type="radio"] {
  position: relative;
  appearance: none;
  border: 2px solid var(--gray-6);
  color: #fff;
  height: 20px;
  width: 20px;
  margin-right: var(--space-sm);
  flex-shrink: 0;
  outline: none; }
  [type="checkbox"]:after,
  [type="radio"]:after {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: calc(100% + 1.25em);
    height: calc(100% + 1.25em);
    border-radius: 50%;
    background: #000;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity .15s linear; }
  [type="checkbox"]:hover,
  [type="radio"]:hover {
    cursor: pointer; }
    [type="checkbox"]:hover:after,
    [type="radio"]:hover:after {
      opacity: .075; }
  [type="checkbox"]:focus:after, [type="checkbox"]:active:after,
  [type="radio"]:focus:after,
  [type="radio"]:active:after {
    opacity: .15; }
  [type="checkbox"]:checked:focus:after,
  [type="radio"]:checked:focus:after {
    opacity: .15; }
  [type="checkbox"]:before,
  [type="radio"]:before {
    content: ' ';
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    background: var(--color-text);
    transition: transform .15s linear;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    will-change: opacity, transform; }
  [type="checkbox"]:checked,
  [type="radio"]:checked {
    border-color: var(--color-text); }
    [type="checkbox"]:checked:before,
    [type="radio"]:checked:before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1); }

input,
select {
  color: var(--color-text); }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }
  fieldset:not(:last-child) {
    margin-bottom: var(--space-xl); }

legend {
  padding-bottom: var(--space-xs);
  margin-bottom: var(--space-lg);
  font-weight: var(--type-weight-meta);
  font-size: var(--text-sm);
  border-bottom: 1px solid var(--color-border);
  width: 100%;
  text-transform: uppercase;
  color: var(--color-text-heading);
  letter-spacing: .042em; }

label {
  font-size: var(--text-sm);
  font-weight: var(--type-weight-meta);
  color: var(--color-text-subtle);
  display: inline-block;
  margin-bottom: var(--space-xxs); }

label em,
legend em {
  font-size: var(--text-sm);
  color: var(--color-text-meta);
  text-transform: none; }

.form__section {
  background: #fff;
  padding: var(--space-lg) var(--space-md); }
  .form__section:not(:last-child) {
    margin-bottom: var(--space-md); }
  .form__section.is--box-shadow {
    box-shadow: 2px 2px 15px -9px rgba(0, 0, 0, 0.65); }
  .form__section.is--alpha {
    background: rgba(255, 255, 255, 0.7); }

.form__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.form__col {
  display: flex; }
  .form__col:not(:last-child) {
    margin-right: var(--space-md); }

.form__group {
  position: relative;
  letter-spacing: .04em;
  line-height: 1.25rem; }
  .form__group:not(:last-child) {
    margin-bottom: var(--space-unit); }
  .form__group .hint-text {
    margin-bottom: var(--space-md); }
  .form__group.is--error-white .hint-text.is--error {
    color: #fff; }

.form__control {
  display: block;
  width: 100%;
  border: 1px solid var(--color-border);
  border-radius: 0;
  font-size: var(--text-sm);
  padding: var(--space-xs);
  transition: none; }
  .form__control:not([size]) {
    min-width: 240px; }
  .form__control.is--auto-width {
    width: auto; }

.form__label-title {
  font-size: var(--text-sm);
  font-weight: var(--type-weight-meta);
  margin: var(--space-md) 0 var(--space-xs) 0; }

.form__floated-label {
  position: relative;
  font-size: inherit;
  font-weight: var(--type-weight-meta);
  width: 100%;
  margin-top: 10px; }
  .form__floated-label .form__control {
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 0;
    padding-right: 0; }
    .form__floated-label .form__control:focus {
      box-shadow: none; }
  .form__floated-label.is--active .form__floated-text {
    font-weight: var(--type-weight-meta);
    top: 0;
    transform: translate(0, -60%) scale(0.75);
    color: rgba(0, 0, 0, 0.5); }

.form__floated-text {
  position: absolute;
  top: 50%;
  left: 0;
  transform-origin: top left;
  transform: translate(0, -50%) scale(1);
  transition: all .1s ease-in-out;
  background: #fff;
  font-size: var(--text-sm);
  color: var(--color-text-subtle); }

.form__message {
  margin-top: var(--space-xs);
  font-size: var(--text-xs);
  font-weight: var(--type-weight-meta); }
  .form__message.is--error {
    color: var(--color-error); }

.input-group {
  position: relative; }
  .input-group__append {
    position: absolute;
    right: 0;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    color: var(--color-text-meta); }

/*
  Field status and validation
*/
input:focus {
  box-shadow: 0 0 15px -2px var(--color-primary);
  outline: none;
  border-color: var(--color-primary); }

input.is--error {
  border-color: var(--color-error); }
  input.is--error:focus {
    box-shadow: 0 0 15px -2px var(--color-error); }

input.is--valid {
  border-color: var(--color-valid); }
  input.is--valid:focus {
    box-shadow: 0 0 15px -2px var(--color-valid); }


